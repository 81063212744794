<template>
  <div class="h-100 container-fluid p-0 d-flex flex-column">
    <div class="text-center pb-3">
      <h2>{{ $t("CONFIGURATIONS") }}</h2>
    </div>
    <div class="flex-full-height mb-5 router-view-scrollable-content">
      <div class="configuration-border justify-content-center">
        <ShopperTraining />
      </div>
      <hr />
      <div class="configuration-border justify-content-center">
        <ShopperReaction />
      </div>
      <hr />
      <div class="configuration-border justify-content-center">
        <h3 class="mb-4">Rejection call</h3>
        <p v-html="$t('REJECTION_CALL_INFO')"></p>
        <Switch
          :initValue="rejectionCall"
          :disabled="activeWaveReadonly"
          v-on:changed="(checked) => updateRejectionCall(checked)"
        />
      </div>
      <hr />
      <div class="configuration-border justify-content-center">
        <h4>{{ $t("ON_SITE") }}</h4>
        <p class="on-site--subtitle break-i18n">
          {{ $t("ON_SITE_SUBTITLE") }}
        </p>
        <p class="on-site--subtext break-i18n">
          {{ $t("ON_SITE_SUBTITLE2") }}
        </p>
        <div data-jp="on-site-configuration-local">
          <OnSiteTable />
        </div>
        <button
          type="button"
          class="btn btn-outline-dark border-radius-0 mb-3"
          data-jp="add-on-site-configuration-local"
          @click="addOnSiteConfig"
          :disabled="isAdminUser || activeWaveReadonly"
        >
          {{ $t("ADD_CONFIGURATION") }}
        </button>
      </div>
      <hr />
      <div class="configuration-border justify-content-center">
        <h4>{{ $t("OFF_SITE_WAVE") }}</h4>
        <p class="on-site--subtitle break-i18n pb-3">
          {{ $t("OFF_SITE_SUBTITLE") }}
        </p>
        <div data-jp="off-site-configuration-local">
          <OffSiteTable />
        </div>
        <button
          type="button"
          class="btn btn-outline-dark border-radius-0 mb-3"
          data-jp="add-off-site-configuration-local"
          @click="addOffSiteConfig"
          :disabled="isAdminUser || activeWaveReadonly"
        >
          {{ $t("ADD_CONFIGURATION") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, onErrorCaptured } from "vue";
import configurationLockService from "@/services/configurationLockService";
import { useStore } from "vuex";
const Switch = defineAsyncComponent(() =>
  import("@/components/switches/Switch")
);
const OnSiteTable = defineAsyncComponent(() =>
  import("@/components/configurations/on-site/OnSiteTable")
);
const OffSiteTable = defineAsyncComponent(() =>
  import("@/components/configurations/off-site/OffSiteTable")
);
const ShopperTraining = defineAsyncComponent(() =>
  import("@/components/configurations/ShopperTraining")
);
const ShopperReaction = defineAsyncComponent(() =>
  import("@/components/configurations/ShopperReaction")
);

export default {
  name: "Configuration",
  components: {
    OffSiteTable,
    OnSiteTable,
    ShopperTraining,
    ShopperReaction,
    Switch
  },
  async setup(props) {
    configurationLockService.unlockConfiguration();
    const store = useStore();
    await store.dispatch("waves/loadInterestedTrainingTypes");
    return {
      configurationLockService: configurationLockService,
    };
  },
  computed: {
    isAdminUser() {
      return this.$store.getters["users/isAdminUser"];
    },
    activeWaveReadonly() {
      return this.$store.getters["waves/getActiveWaveReadonly"];
    },
    rejectionCall() {
      return this.$store.getters["waves/getRejectionCall"];
    },
  },
  methods: {
    async addOnSiteConfig() {
      await this.$store.dispatch("configurations/addNewConfiguration", true);
      // reset duplicate configuration window
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
    },
    async addOffSiteConfig() {
      await this.$store.dispatch("configurations/addNewConfiguration", false);
      // reset duplicate configuration window
      this.$store.commit("configurations/setDuplicateErrorConfigurationId", -1);
    },
    async updateRejectionCall(rejectionCall) {
      await this.$store.dispatch("waves/updateRejectionCall", rejectionCall);
    }
  },
};
</script>

<style lang="scss" scoped>
.configuration-border {
  margin-top: 30px;
  margin-bottom: 30px;
}
.bottom-space {
  margin-bottom: 50px;
}

.on-site {
  &--subtitle {
    padding: 0.1em;
    margin: 0;
  }
  &--subtext {
    line-height: 1.1;
    font-size: 0.9rem;
  }
}
</style>
